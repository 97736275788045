import React from 'react';

import TemplateLegalPage from '../../blocks/templateLegalPage';

import RectangleLink from "../../components/RectangleLink";
import Clarification from "../../components/Clarification";

import enMessages from '../../i18n/legal/ombudsman/en.js'
import ruMessages from '../../i18n/legal/ombudsman/ru.js'

import enMainMessages from '../../i18n/LegalInformation/en.js'
import ruMainMessages from '../../i18n/LegalInformation/ru.js'

const messages = {
    en: enMessages,
    ru: ruMessages
}

const messagesMain = {
    en: enMainMessages,
    ru: ruMainMessages
}

const OmbudsmanPage = ({ pathContext: { locale } }) => {
    const section = 'ombudsman';

    const isBrowser = typeof window !== "undefined"
    if (isBrowser) {
        if (locale === 'en'){
            window.location.replace('/en/legal/requisite');
        }
    }

    return (
        <TemplateLegalPage
            locale={locale}
            section={section}
            messages={messages[locale]}
            messagesMain={messagesMain[locale]}
        >
            <div className="LegalInformation-Text" dangerouslySetInnerHTML={{ __html:messages[locale].content.information }}/>
        </TemplateLegalPage>
    )
};

export default OmbudsmanPage;
