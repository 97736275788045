import audit_2019 from '../../../assets/files/ru/Audit_2019.pdf';

export default {
    seo: {
      title: 'Финансовый уполномоченный | Банк 131',
      description: 'Финансовый уполномоченный'
    },
    title: 'Финансовый уполномоченный',
    content: {
         information: '<h3>Информация о праве потребителей финансовых услуг на направление обращения финансовому уполномоченному</h3>'
         +'<p>С 1 января 2021 года действует новый досудебный порядок урегулирования споров потребителей с кредитными организациями.</p>'
         +'<p>В случае если кредитная организация отказывается удовлетворить требования потребителя, до обращения в суд потребитель для урегулирования спора должен обратиться к финансовому уполномоченному.</p>'
         +'<p>Должность финансового уполномоченного учреждена Федеральным законом от 4 июня 2018 года № 123-ФЗ «Об уполномоченном по правам потребителей финансовых услуг» (далее – Закон).</p>'
         +'<p>Финансовый уполномоченный рассматривает имущественные требования потребителя, размер которых не превышает 500000 рублей.</p>'
         +'<p>Обращение потребителя финансовому уполномоченному может быть направлено в электронной форме <a href="https://finombudsman.ru/lk/login" target="_blank">через личный кабинет</a> на официальном сайте финансового уполномоченного или в письменной форме.</p>'
         +'<p>Прием и рассмотрение обращений потребителей осуществляется финансовым уполномоченным бесплатно.</p>'
         +'<p>До направления обращения финансовому уполномоченному потребитель должен обратиться с заявлением (претензией) в кредитную организацию. Данный претензионный порядок установлен статьей 16 Закона и является обязательным для потребителей.</p>'
         +'<p>С подробной информацией о порядке направления обращения финансовому уполномоченному можно ознакомиться <a href="https://finombudsman.ru/" target="_blank">на официальном сайте</a> финансового уполномоченного.</p>'
         +'<p>Официальный сайт финансового уполномоченного: <a href="https://finombudsman.ru/" target="_blank">www.finombudsman.ru</a> </p>'
         +'<p>Номер телефона службы обеспечения деятельности финансового уполномоченного: 8 (800) 200-00-10 (бесплатный звонок по России).</p>'
         +'<p>Место нахождения службы обеспечения деятельности финансового уполномоченного: 119017, г. Москва, Старомонетный переулок, дом 3.</p>'
         +'<p>Почтовый адрес службы обеспечения деятельности финансового уполномоченного: 119017, г. Москва, Старомонетный переулок, дом 3, получатель АНО «СОДФУ».</p>'
     }
}